import React, { useEffect, useState } from "react";

import { DatePicker, Button, Select, Input } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

import {
  admissionInformationSelector,
  getSurgeryList,
  getSurgeryData,
} from "../../Slices/AdmissionSlices";

import FilterButton from "./Components/FilterButtons";
import DataPatientCard from "./Components/DataCards";
import DoctorAdmission from "./Components/DoctorAdmission";
import Scrollbars from "react-custom-scrollbars";

import moment from "moment";

import DoctorSurgery from "./Components/DoctorSurgery";

const Admission = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const dateFormat = "DD/MM/YYYY";
  const [selectedDate, setSelectedDate] = useState([]);
  const [dateSelected, setDateSelected] = useState(7);
  const [activeButton, setActiveButton] = useState([]);

  const [selectedSurgeryTypeId, setSurgeryId] = useState();
  const [searchedItem, setSearchItem] = useState("");

  const [searchedQuery, setSearchQuery] = useState();

  const doctorProfile = JSON.parse(localStorage.getItem("doctorProfile"));

  const dispatch = useDispatch();

  const { surgeryDataList, surgeryData, isFetching } = useSelector(
    admissionInformationSelector
  );

  const selectSurgeryType = (value) => {
    setSurgeryId(value);
  };

  const onSearch = (val) => {
    dispatch(
      getSurgeryList({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        query: val,
      })
    );
    setSearchItem(val);
  };

  const getDate = (from_date, to_date, days) => {
    setDateSelected(days);
    dispatch(
      getSurgeryList({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
        query: searchedItem,
      })
    );
    dispatch(
      getSurgeryData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
        surgeryList: selectedSurgeryTypeId,
        filterText: searchedQuery,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getSurgeryList({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        query: searchedItem,
      })
    );
    dispatch(
      getSurgeryData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        surgeryList: selectedSurgeryTypeId,
        filterText: searchedQuery,
      })
    );
  }, []);

  const filterResult = () => {
    setActiveButton(null);
    dispatch(
      getSurgeryList({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: selectedDate[0]
          ? moment(selectedDate[0])?.format("YYYY-MM-DD")
          : moment(moment().subtract(dateSelected, "d")).format("YYYY-MM-DD"),

        to_date: selectedDate[1]
          ? moment(selectedDate[1])?.format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD"),
        query: searchedItem,
      })
    );

    dispatch(
      getSurgeryData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: selectedDate[0]
          ? moment(selectedDate[0])?.format("YYYY-MM-DD")
          : moment(moment().subtract(dateSelected, "d")).format("YYYY-MM-DD"),
        to_date: selectedDate[1]
          ? moment(selectedDate[1])?.format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD"),
        surgeryList: selectedSurgeryTypeId,
        filterText: searchedQuery,
      })
    );
  };

  const onChange = (dateString) => {
    setSelectedDate(dateString);
  };

  return (
    <>
      <Scrollbars style={{ height: "89vh" }}>
        <div className="header-container margin-top-surgery">
          <div className="range-selector-surgery">
            <RangePicker
              defaultValue={[
                moment(moment().subtract(7, "d")),
                moment(new Date()),
              ]}
              format={dateFormat}
              onChange={onChange}
              value={
                selectedDate?.length
                  ? [selectedDate[0], selectedDate[1]]
                  : [
                      moment(moment().subtract(dateSelected, "d")),
                      moment(new Date()),
                    ]
              }
              className="custom-range-picker"
            />
          </div>
          <Select
            showSearch
            style={{ width: 200 }}
            className="surgical-type-selector"
            placeholder="Search a surgery"
            optionFilterProp="children"
            allowClear={true}
            onSearch={onSearch}
            onChange={selectSurgeryType}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {surgeryDataList?.surgeries?.map((item) => {
              return <Option value={item.id}>{item.name}</Option>;
            })}
          </Select>
          <Input
            className={!selectedSurgeryTypeId ? "none" : ""}
            placeholder="Custom search"
            style={{ width: 200 }}
            disabled={!selectedSurgeryTypeId}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
          <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={() => filterResult()}
            className="filter-date-button"
          >
            Filter
          </Button>
        </div>
        <FilterButton setFilterDate={getDate} setSelectedDate={setSelectedDate} activeButton={activeButton} setActiveButton={setActiveButton} />
        <DataPatientCard
          totalNumberOfSurgeries={surgeryData?.total_number_of_surgeries}
          isFetching={isFetching}
        />
        <div className="top-header-filter">
          <DoctorAdmission
            revenuePerSurgeryList={surgeryData?.revenue_per_surgery_list}
            isFetching={isFetching}
          />
          <DoctorSurgery
            revenuePerSurgeryList={
              surgeryData?.surgeries_performed_by_doctor_list
            }
            isFetching={isFetching}
          />
        </div>
      </Scrollbars>
    </>
  );
};

export default Admission;
