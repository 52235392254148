import { List, Avatar, Spin } from "antd";

const DoctorAdmission = ({ admissions, isFetching}) => {
  let total = 0;

  admissions?.map((items) => (total = total + items.total_revenue_amout));

  return (
    isFetching ?
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center',padding:20 }}>
        <Spin />
      </div>
    :
    <>
      <List
        itemLayout="horizontal"
        dataSource={admissions}
        className="revenue-list"
        header={
          <div>
            <h1 className="graph-heading-container">
              Revenue from primary doctor's
            </h1>

            <h1 className="graph-heading-container total-container">
              Total Amount :-{" "}
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(parseInt(total).toFixed(2))}
            </h1>
          </div>
        }
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar src="https://www.medstar911.org/wp-content/uploads/2019/11/Doctor-placeholder-image.jpg" />
              }
              title={item?.doctor_profile.full_name}
              description={new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(parseInt(item?.total_revenue_amout).toFixed(2))}
              className="list-data"
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default DoctorAdmission;
