import styled from "styled-components";

const RightSideBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 2;

  .support-container {
    display: flex;
  }

  section.ant-layout.ant-layout-has-sider {
    justify-content: flex-end;
  }

  aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger,
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
    min-height: 100vh;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .dark-mode {
    background-color: rgb(6, 12, 35);
    color: #fff;
  }

  ant-layout-sider-children {
    position: relative;
  }

  .bottom-btn {
    width: 100% !important;
    position: absolute;
    bottom: 85px;
  }

  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed,
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light,
  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.bottom-btn {
    color: #868c91;
  }

  aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed.ant-layout-sider-has-trigger,
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.dark-mode {
    max-width: 20px !important;
    min-width: 56px !important;
  }

  aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed.ant-layout-sider-has-trigger
    .ant-layout-sider-trigger,
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.dark-mode
    .ant-layout-sider-trigger {
    width: 56px !important;
  }

  .ant-modal-body {
    padding: 0;
  }
`;

export { RightSideBarWrapper };
