import React from "react";
import { ParentWrapper } from "./logoStyle";

const Logo = () => {
  return (
    <ParentWrapper>
      <h1 className="pred-logo">
        <span className="pred-red">Pred</span>
        <span className="pred-gray">.</span>
        <span className="pred-blue">health</span>
      </h1>
    </ParentWrapper>
  );
};

export default Logo;
