/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useMemo } from "react";

import throttle from "lodash/throttle";

import { makeStyles, withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import PatientCardMainContentBoard from "./PatientCardMainContentBoard";
import useWindowDimensions from "./useWindowDimensions ";

import { ParentWrapper } from "./style";

const tabHeight = 69;

const StyledTabs = withStyles({
  borderBottom: "1px solid #e8e8e8",
  indicator: {
    display: "flex",
    backgroundColor: "#045185",
    "& > div": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})((props) => (
  <Tabs
    {...props}
    orientation={props.width > 768 ? "vertical" : "horizontal"}
    lazy="true"
    sx={{
      borderRight: 1,
      borderColor: "divider",
      height: props.width > 768 ? "70vh" : "0",
    }}
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
    tabindicatorprops={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
));

const StyledTab = withStyles(() => ({
  root: {},
  indicator: {
    display: "flex",
    backgroundColor: "#045185",
    "& > div": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
  ".css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    color: "red",
  },
}))((props) => (
  <Tab
    orientation="horizontal"
    disableRipple
    lazy="true"
    {...props}
    tabindicatorprops={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  indicator: {
    padding: 2,
  },
  demo2: {
    width: "224px",
  },
  mobileView: {
    width: "100%",
  },
}));

const makeUnique = (hash, unique, i = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;
  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }
  return makeUnique(hash, unique, i + 1);
};

const textToHash = (text, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "")
    ),
    unique
  );
};

const noop = () => {};

const useThrottledOnScroll = (callback, delay, containerRef) => {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  useEffect(() => {
    if (throttledCallback === noop) return undefined;
    containerRef?.current?.addEventListener("scroll", throttledCallback);
    return () => {
      containerRef?.current?.removeEventListener("scroll", throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
};

const ScrollSpyTabs = (props) => {
  const containerRef = useRef();
  const { width } = useWindowDimensions();
  const [activeState, setActiveState] = React.useState(null);
  const [activePatientProfileData, setActivePatientData] = useState([]);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const { tabsInScroll, activePatientData, setActiveTab } = props;

  let itemsServer = tabsInScroll.map((tab) => {
    const hash = textToHash(tab.text);
    return {
      icon: tab.icon || "",
      text: tab.text,
      component: tab.component,
      hash: hash,
      key: hash,
      node: document.getElementById(hash),
    };
  });
  const DrawerAction = () => {
    setVisibleDrawer(!visibleDrawer);
  };

  const itemsClientRef = useRef([]);

  useEffect(() => {
    itemsClientRef.current = itemsServer;
  }, [itemsServer]);

  const clickedRef = useRef(false);
  const unsetClickedRef = useRef(null);
  const findActiveIndex = React.useCallback(() => {
    if (activeState === null) setActiveState(itemsServer[0].hash);
    if (clickedRef.current) return;

    let active;
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      if (containerRef?.current?.scrollTop < 0) {
        active = { hash: null };
        break;
      }

      const item = itemsClientRef.current[i];

      if (
        item.node &&
        item.node.offsetTop <
          containerRef?.current?.scrollTop +
            containerRef?.current?.clientHeight / 8 +
            tabHeight
      ) {
        active = item;
        break;
      }
    }
    setActiveTab(active?.hash);
    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, itemsServer]);

  useThrottledOnScroll(
    itemsServer.length > 0 ? findActiveIndex : null,
    166,
    containerRef
  );

  const handleClick = (hash) => () => {
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== hash) {
      setActiveState(hash);

      if (containerRef?.current)
        containerRef.current.scrollTo({
          top:
            document?.getElementById(hash)?.getBoundingClientRect()?.top +
            containerRef?.current?.scrollTop -
            130,
          behavior: "smooth",
        });
    }
  };

  useEffect(() => {}, [activePatientData]);

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    []
  );

  const classes = useStyles();

  return (
    <ParentWrapper style={{ display: width > 768 ? "" : "block" }}>
      <nav className={width > 768 ? classes.demo2 : classes.mobileView}>
        {width > 768 ? (
          <PatientCardMainContentBoard
            activePatientProfileData={activePatientProfileData}
            DrawerAction={DrawerAction}
            visibleDrawer={visibleDrawer}
          />
        ) : (
          <></>
        )}

        <StyledTabs
          value={activeState ? activeState : itemsServer[0].hash}
          width={width}
        >
          {itemsServer.map((item2) => (
            <StyledTab
              key={item2.hash}
              label={item2.text}
              onClick={handleClick(item2.hash)}
              value={item2.hash}
            />
          ))}
        </StyledTabs>
        <div className={classes.indicator} />
      </nav>

      <div className="container" ref={containerRef}>
        {itemsServer.map((item1) => (
          <article
            id={item1.hash}
            key={item1.text}
            className="content-container"
          >
            {item1.component}
          </article>
        ))}
      </div>
    </ParentWrapper>
  );
};

export default ScrollSpyTabs;
