import { Card, Spin } from "antd";
import {
  UsergroupAddOutlined,
  FieldTimeOutlined,
  ScissorOutlined,
} from "@ant-design/icons";
import { FaBed } from "react-icons/fa";

const DataPatientCard = ({ totalNumberOfSurgeries, isFetching }) => {
  return (
    <div className="col-container-surgery">
      <Card bordered={false}>
        <div className="content">
          <div className="icon">
            <ScissorOutlined />
          </div>
          <div className="content-values">
            <h2 className="card-title">Number of surgery</h2>
            {isFetching ? (
              <>
                <Spin />
              </>
            ) : (
              <span className="card-value">
                {totalNumberOfSurgeries ? totalNumberOfSurgeries : 0}
              </span>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DataPatientCard;
