import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import { userSlice } from "../pages/User/UserSlice";

import { admissionInformationSlice } from "../pages/DataInsights/Components/Slices/AdmissionSlices";
import { invoiceInformationSlice } from "../pages/DataInsights/Components/Slices/InvoiceSlice";
import { bedDataInformationSlice } from "../pages/DataInsights/Components/Slices/BedDataSlice";

const reducers = combineReducers({
  user: userSlice.reducer,

  admissionInformationDetails: admissionInformationSlice.reducer,
  invoiceInformationDetails: invoiceInformationSlice.reducer,
  bedDataInformationDetails: bedDataInformationSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["radiologyInformationDetails"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});
