import { Card, Button, Spin, Alert } from "antd";
import { FaRupeeSign } from "react-icons/fa";
import { CloseOutlined } from "@ant-design/icons";

const DataPatientCard = (props) => {
  // console.log(props)
  const {
    totalRevenue,
    totalCollected,
    totalOutstanding,
    totalDiscount,
    addedCards,
    removeCards,
    isFetching,
  } = props;
  return (
    <div className="col-container">
      <Card bordered={false}>
        <div className="content">
          <div className="icon">
            <FaRupeeSign />
          </div>
          <div className="content-values">
            <h2 className="card-title">Total Revenue</h2>
            {isFetching ? (
              <>
                <Spin />
              </>
            ) : (
              <span className="card-value">
                {totalRevenue
                  ? new Intl.NumberFormat("en-IN", {}).format(
                      parseInt(totalRevenue)?.toFixed(0)
                    )
                  : 0}
                <span className="decimal-value">
                  {Math.round((totalRevenue - parseInt(totalRevenue)) * 10)
                    ? `.${Math.abs(Math.round(
                        (totalRevenue - parseInt(totalRevenue)) * 10
                      ))}`
                    : ""}
                </span>
              </span>
            )}
          </div>
        </div>
      </Card>
      <Card bordered={false}>
        <div className="content">
          <div className="icon">
            <FaRupeeSign />
          </div>
          <div className="content-values">
            <h2 className="card-title">Total Collected</h2>
            {isFetching ? (
              <>
                <Spin />
              </>
            ) : (
              <span className="card-value">
                {totalCollected
                  ? new Intl.NumberFormat("en-IN", {}).format(
                      parseInt(totalCollected)?.toFixed(0)
                    )
                  : 0}
                <span className="decimal-value">
                  {Math.round((totalCollected - parseInt(totalCollected)) * 10)
                    ? `.${Math.abs(Math.round(
                        (totalCollected - parseInt(totalCollected)) * 10
                      ))}`
                    : ""}
                </span>
              </span>
            )}
          </div>
        </div>
      </Card>
      <Card bordered={false} className="avg-len-container">
        <div className="content">
          <div className="icon avg-len">
            <FaRupeeSign />
          </div>
          <div className="content-values avg-len-container">
            <h2 className="card-title avg-len">Total Outstanding</h2>
            {isFetching ? (
              <>
                <Spin />
              </>
            ) : (
              <span className="card-value">
                {totalOutstanding
                  ? new Intl.NumberFormat("en-IN", {}).format(
                      parseInt(totalOutstanding)?.toFixed(0)
                    )
                  : 0}
                <span className="decimal-value">
                  {Math.round(
                    (totalOutstanding - parseInt(totalOutstanding)) * 10
                  )
                    ? `.${Math.abs(Math.round(
                        (totalOutstanding - parseInt(totalOutstanding)) * 10
                      ))}`
                    : ""}
                </span>
              </span>
            )}
          </div>
        </div>
      </Card>
      <Card bordered={false} className="avg-len-container">
        <div className="content">
          <div className="icon avg-len">
            <FaRupeeSign />
          </div>
          <div className="content-values avg-len-container">
            <h2 className="card-title avg-len">Total Discount</h2>
            {isFetching ? (
              <>
                <Spin />
              </>
            ) : (
              <span className="card-value">
                {totalDiscount
                  ? new Intl.NumberFormat("en-IN", {}).format(
                      parseInt(totalDiscount)?.toFixed(0)
                    )
                  : 0}
                <span className="decimal-value">
                  {Math.round((totalDiscount - parseInt(totalDiscount)) * 10)
                    ? `.${Math.abs(Math.round(
                        (totalDiscount - parseInt(totalDiscount)) * 10
                      ))}`
                    : ""}
                </span>
              </span>
            )}
          </div>
        </div>
      </Card>

      {addedCards && addedCards.length > 0 ? (
        addedCards.map((item) => {
          return item.cardAdded ? (
            <>
              <Card
                bordered={false}
                className="avg-len-container"
                key={item?.uid}
              >
                <div className="button-container-card" key={item?.uid}>
                  <Button
                    icon={<CloseOutlined />}
                    size="small"
                    onClick={() => {
                      removeCards(item.uid);
                    }}
                  />
                </div>
                <div className="content">
                  <div className="icon avg-len">
                    <FaRupeeSign />
                  </div>
                  <div className="content-values avg-len-container">
                    <h2 className="card-title avg-len">{item.title}</h2>
                    {isFetching ? (
                      <>
                        <Spin />
                      </>
                    ) : (
                      <span className="card-value">
                        {item.value
                          ? new Intl.NumberFormat("en-IN", {}).format(
                              parseInt(item.value)?.toFixed(0)
                            )
                          : 0}
                        <span className="decimal-value">
                          {Math.round((item.value - parseInt(item.value)) * 10)
                            ? `.${Math.round(
                                (item.value - parseInt(item.value)) * 10
                              )}`
                            : ""}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </Card>
            </>
          ) : (
            <></>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default DataPatientCard;
