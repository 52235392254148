import CommonImages from "../../../../const/CommonImages";

const ImageContainer = () => {
  return (
    <>
      <div className="image-container">
        <img
          src={CommonImages.bannerImage}
          alt="banner"
          className="banner-image"
        />
      </div>
    </>
  );
};

export default ImageContainer;
