import styled from "styled-components";

const ParentWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  .mobile-view {
    display: block !important;
  }

  .Mui-selected {
    color: #045185;
    font-weight: 550;
  }

  .makeStyles-mobileView-4 .Mui-selected {
    padding: 5px;
    font-size: 12px;
  }

  .makeStyles-mobileView-4 .MuiButtonBase-root {
    font-size: 12px;
    padding: 11px;
  }

  .MuiTabs-flexContainerVertical button {
    color: #045185;
    font-size: 13.5px;
    margin: -4px 2px;
  }

  .site-drawer-render-in-current-wrapper {
    margin-bottom: 12px;
  }

  .MuiButtonBase-root {
    text-transform: capitalize;
  }

  .MuiTabs-flexContainer {
    align-items: baseline;
  }

  .MuiTabs-indicator {
    background-color: #045185;
  }

  .pred-logo {
  }

  .pred-red {
    color: #045185;
  }

  .login-txt {
    color: #045185;
  }

  .pred-gray {
    color: #4d4d4d;
  }

  .pred-blue {
    color: #e21c21;
  }

  .contact-info a:hover {
    font-weight: 500;
    font-size: 13.5px;
  }

  .image-container img {
    width: 75px;
    height: 75px;
    border-radius: 50px;
  }

  .name {
    margin: -8px;
    display: block;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    color: #045185;
    text-shadow: 0 0 0.25px #045185;
  }

  .block {
    display: block;
    font-size: 13px;
    text-align: initial;
    text-transform: capitalize;
    color: #045185;
  }

  .title {
    font-weight: 500;
  }

  .content-container {
    margin-bottom: 10%;
  }

  .ant-drawer-body {
    padding: 23px;
  }

  .more-btn {
    font-size: 12px;
    margin-top: 13px;
    color: #045185;
    text-shadow: 0 0 0.25px #045185;
  }

  .MuiButtonBase-root {
    align-items: start;
    white-space: unset;
  }

  .more-btn span {
    text-decoration: underline;
    cursor: pointer;
  }

  .container article {
    padding: 0 12px;
  }

  .site-drawer-render-in-current-wrapper {
    position: relative;
    height: 150px;
    width: 164px;
    padding: 10px;
    overflow: hidden;
    text-align: center;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    border: 1px solid #ddd;
    background-color: #fff;
  }

  .online-dot {
    height: 10px;
    width: 10px;
    background-color: #60ec82;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 10px;
  }

  .MuiSvgIcon-root {
    position: relative;
    top: 2px;
  }
`;

export { ParentWrapper };
