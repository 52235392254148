/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Tabs, Result } from "antd";

import DashboardContent from "./DashboardContent";
import { TabWrapper } from "./tabStyle";
import ScrollSpyTab from "./Components/ScrollSpyTab";
import Admission from "../Cards/Admission";
import Revenue from "../Cards/Revenue";
import Surgery from "../Cards/Surgery";
import Referals from "../Cards/Referal";

const MainContent = ({ activePatientData, patientData, activeTabKey }) => {
  const project = () => {
    switch (activeTabKey) {
      case 1:
        return <Admission />;
      case 2:
        return <Revenue />;
      case 3:
        return <Surgery />;
      case 4:
        return <Referals />;
      default:
        return <h1>No project match</h1>;
    }
  };
  return <TabWrapper>{project()}</TabWrapper>;
};

export default MainContent;
