import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchRadiologyDetails = createAsyncThunk(
  "users/fetchRadiologyDetails",
  async ({ token, patientId, doctorId }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://app.pred.health/api/v2/get-uploaded-radiology-documents/${doctorId}/${patientId}/0/5/`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data, patientId };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchReports = createAsyncThunk(
  "users/fetchReports",
  async ({ token, reportId }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://app.pred.health/api/v2/view-radiology-report/${reportId}/`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      let data = await response;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const RadiologyInformationSlice = createSlice({
  name: "radiologyInformationDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    radiologyDetails: [],
    fetchedReport: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [fetchRadiologyDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.radiologyDetails = [
        ...state.radiologyDetails,
        { patientId: payload.patientId, document: payload.documents },
      ];
      return state;
    },
    [fetchRadiologyDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [fetchRadiologyDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchReports.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.fetchedReport = payload;
      return state;
    },
    [fetchReports.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [fetchReports.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = RadiologyInformationSlice.actions;

export const radiologyInformationSelector = (state) =>
  state.radiologyInformationDetails;
