import React, { useState } from "react";

import { List, Avatar, Spin } from "antd";
import { Radio } from "antd";

const DoctorAdmission = ({ admissions, isFetching }) => {
  const [selectedDataFlow, setSelectedDataFlow] = useState("dr");

  const handleChange = (value) => {
    setSelectedDataFlow(value.target.value);
  };

  return (
    isFetching ? (
      <div className="pie-graph-container" style={{ display: 'flex', width: window.innerWidth > 600 ? '49%' : '100%', justifyContent: 'center', padding: 30, margin: 10 }}>
        <Spin />
      </div>
    ) :
    <>
      {admissions && admissions?.dr ? (
        <List
          itemLayout="horizontal"
          dataSource={admissions[selectedDataFlow]}
          header={
            <div>
              <h1 className="graph-heading-container">
                Referrals Data for individual's admissions
              </h1>
              <div className="radio-button-gender">
                <Radio.Group
                  defaultValue="dr"
                  buttonStyle="solid"
                  onChange={handleChange}
                  className="gender-selector"
                >
                  <Radio.Button value="dr">Doctor</Radio.Button>
                  <Radio.Button value="pt">Patient</Radio.Button>
                  <Radio.Button value="on">Online</Radio.Button>
                  <Radio.Button value="ot">Other</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          }
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar src="https://www.medstar911.org/wp-content/uploads/2019/11/Doctor-placeholder-image.jpg" />
                }
                title={
                  item?.doctor_profile?.full_name
                    ? item?.doctor_profile?.full_name
                    : item.referred_by
                }
                description={new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(item?.total.toFixed(2))}
                className="list-data"
              />
            </List.Item>
          )}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DoctorAdmission;
