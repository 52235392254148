import { notification } from "antd";

const errorNotification = () => {
  const args = {
    message: "Unable to login with provided credentials",
    duration: 0,
    className: "error-text",
    style: {
      backgroundColor: "#fff2f0",
      border: " 1px solid #ffbbb9",
      width: "100%",
    },
  };
  notification.error(args);
};

const otpSuccessNotification = () => {
  const args = {
    message: "OTP Sent Successfully",
    duration: 2,
    style: {
      backgroundColor: "#F6FFED",
      border: " 1px solid #52C41A",
      width: "100%",
    },
  };
  notification.success(args);
};

const otpErrorNotification = () => {
  const args = {
    message: "The Phone number you have entered does not match.",
    duration: 2,
    style: {
      backgroundColor: "#fff2f0",
      border: " 1px solid #ffbbb9",
      width: "100%",
    },
  };
  notification.error(args);
};

export { errorNotification, otpSuccessNotification, otpErrorNotification };
