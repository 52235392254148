import Button from "@mui/material/Button";
import moment from "moment";

const FilterButton = ({ setFilterDate, setSelectedDate, activeButton, setActiveButton }) => {

  const dateChange = (currentDate, days) => {
    setSelectedDate([moment(currentDate), moment(new Date())])
    setActiveButton(days);

    setFilterDate(currentDate, new Date(), days);
  };

  return (
    <div className="button-container">
      <Button
        variant="contained"
        className={
          activeButton === 0 ? "filter-button active" : "filter-button"
        }
        onClick={() => {
          dateChange(moment().format("YYYY-MM-DD"), 0);
        }}
      >
        Today
      </Button>
      <Button
        variant="contained"
        className={
          activeButton === 7 ? "filter-button active" : "filter-button"
        }
        onClick={() => {
          dateChange(moment().subtract(7, "d").format("YYYY-MM-DD"), 7);
        }}
      >
        Last Week
      </Button>
      <Button
        variant="contained"
        className={
          activeButton === 31 ? "filter-button active" : "filter-button"
        }
        onClick={() => {
          dateChange(moment().subtract(1, "months").format("YYYY-MM-DD"), 31);
        }}
      >
        Last Month
      </Button>
      <Button
        variant="contained"
        className={
          activeButton === 365 ? "filter-button active" : "filter-button"
        }
        onClick={() => {
          dateChange(moment().subtract(1, "year").format("YYYY-MM-DD"), 365);
        }}
      >
        Last Year
      </Button>
    </div>
  );
};

export default FilterButton;
