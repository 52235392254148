import React from "react";

//importing antd components
import { Form, Input, Checkbox } from "antd";

//importing common components
import Logo from "../../../../../components/common/logo";
import SubmitButton from "../../../../../components/commonFormElements/SubmitButton";

const CredentialsLoginForm = (props) => {
  const {
    slideUp,
    slideDown,
    setSlideUP,
    setSlideDown,
    onFinish,
    onFinishFailed,
    isFetching,
  } = props;

  var imgUrl = `${process.env.PUBLIC_URL}/pred_health_wo_bg.png`

  return (
    <div className={!slideUp ? "signup" : "signup slide-up"}>
      <h2
        className={slideDown ? "form-title" : "form-title hide-title"}
        id="signup"
        onClick={() => {
          setSlideUP(!slideUp);
          setSlideDown(false);
        }}
      >
        <span>or</span>Login with credentials
      </h2>
      {/* <Logo /> */}
      <img
        src={imgUrl}
        alt="logo"
        width="100px"
        className="omichealth-logo"
      />
      <Form
        name="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="on"
      >
        <Form.Item
          name="username"
          className="login-form-item"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input placeholder="Enter your username" />
        </Form.Item>

        <Form.Item
          name="password"
          className="login-form-item"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <SubmitButton buttonTxt="LOGIN" isFetching={isFetching} />
      </Form>
    </div>
  );
};

export default CredentialsLoginForm;
