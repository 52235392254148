import styled from "styled-components";

const NavigationWrapper = styled.div`
  padding: 0 25px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  .user-section {
    display: flex;
    justify-content: space-between;
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .pred-logo {
    margin: 0;
    font-size: 25px;
    margin-left: 17px;
    position: relative;
    bottom: 3px;
  }

  .toggle-icon {
    display: flex;
  }

  .color-white {
    color: white !important;
  }

  .dark-menu {
    color: #fff !important;
  }

  .userMenu {
    margin-bottom: 0;
    display: flex;
    list-style: none;
    font-size: 21px;
  }

  .userMenu li {
    margin-right: 35px;
    position: relative;
    top: 3px;
  }

  .userMenu a {
    color: #868c91;
  }

  .userMenu li:last-child {
    margin: 0;
    position: relative;
    top: 0;
  }

  .avatar-container {
    width: 38px;
    height: 38px;
    border-radius: 32px;
  }

  .account-item {
    fill: red !important;
  }

  .dropdown-btn > .ant-dropdown-trigger {
    border: none;
    background-color: red !important;
  }

  .dropdown-btn > .ant-dropdown-trigger > span {
    background-color: red !important;
  }

  .omic-health-app-icon {
    width: 70px;
    height: 21px;
    position: relative;
    top: 20px;
    left: 14px;
  }
`;

export { NavigationWrapper };
