import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchReportsDetails = createAsyncThunk(
  "users/fetchReportDetails",
  async ({ token, doctorId, patientId }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://app.pred.health/api/get-all-uploaded-pathology-reports/${patientId}/?doctor_profile_id=${doctorId}`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data, patientId };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchProcedureDetails = createAsyncThunk(
  "users/fetchProcedureDetails",
  async ({ token, doctorId, patientId, reportId }, thunkAPI) => {
    try {
      const response = await fetch(
        `https://app.pred.health/api/get-procedure-details/${reportId}/?doctor_profile_id=${doctorId}&patient_profile_id=${patientId}`,
        {
          method: "GET",
          credentials: "include",

          headers: {
            Accept: "application/json",
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data, patientId };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const reportsInformationSlice = createSlice({
  name: "reportsInformationDetails",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    reportsDetails: [],
    pathalogyReportDetails: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [fetchReportsDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.reportsDetails = [
        ...state.reportsDetails,
        { patientId: payload.patientId, document: payload },
      ];
      return state;
    },
    [fetchReportsDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [fetchReportsDetails.pending]: (state) => {
      state.isFetching = true;
      state.pathalogyReportDetails = [];
    },
    [fetchProcedureDetails.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.pathalogyReportDetails = payload;
      return state;
    },
    [fetchProcedureDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },
    [fetchProcedureDetails.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = reportsInformationSlice.actions;

export const reportInformationSelector = (state) =>
  state.reportsInformationDetails;
