import { CloseOutlined } from "@ant-design/icons";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

import { Button } from "antd";
import moment from "moment";

const BarGraph = ({ title, data, type, removeDashboard, index, allowCard }) => {
  let data3 = [];

  data3 = data?.map((item) => {
    return {
      date: moment(item?.date).format("DD/MM/YYYY"),
      amount: item?.amount,
    };
  });

  return !allowCard ? (
    <>
      <div className="bar-graph-container-revenue">
        <div className="dynamic-charts">
          {type === "Line-Graph" ? (
            <>
              <h1 className="graph-heading-container margin-top">{title}</h1>
              <div className="button-container-graph">
                <Button
                  type="primary"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    removeDashboard(index);
                  }}
                />
              </div>

              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={500}
                  data={data3}
                  margin={{
                    top: 50,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="amount"
                    stroke="#045185"
                    activeDot={{ r: 5 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </>
          ) : (
            <>
              <h1 className="graph-heading-container margin-top">{title}</h1>
              <div className="button-container-graph">
                <Button
                  type="primary"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    removeDashboard(index);
                  }}
                />
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={data3}
                  margin={{
                    top: 50,
                    right: 20,
                    left: 20,
                    bottom: 5,
                  }}
                  barSize={20}
                >
                  <XAxis
                    dataKey="date"
                    scale="point"
                    padding={{ left: 10, right: 10 }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Bar
                    dataKey="amount"
                    fill="#045185"
                    background={{ fill: "#eee" }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default BarGraph;
