/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Dropdown, Tooltip } from "antd";
import {
  BellOutlined,
  MenuFoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";

import InformationCard from "./Components/InformationCard";
import { NavigationWrapper } from "./style";
import Logo from "../../../../components/common/logo";

const NavigationBar = ({ darkMode, LeftSideBar, collapsed, userDetails }) => {
  return (
    <NavigationWrapper
      className={"dark-nav"}
      style={{ backgroundColor: darkMode ? "rgb(6, 12, 35)" : "" }}
    >
      <div className="user-section">
        <div
          className={darkMode ? "toggle-icon dark-menu" : "toggle-icon"}
          onClick={LeftSideBar}
        >
          {collapsed ? (
            <MenuOutlined className={"trigger"} />
          ) : (
            <MenuFoldOutlined className={"trigger"} />
          )}
          <Logo />
        </div>

        <ul className="userMenu">
          <li>
            <Tooltip placement="bottom" title={"Notification"}>
              <a className={darkMode ? "dark-menu" : ""}>
                <BellOutlined />
              </a>
            </Tooltip>
          </li>
          <li>
            {
              <Dropdown
                overlay={<InformationCard userDetails={userDetails} />}
                className="dropdown-btn"
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    src="https://www.w3schools.com/howto/img_avatar.png"
                    alt="avatar"
                    className="avatar-container"
                  />
                </a>
              </Dropdown>
            }
          </li>
        </ul>
      </div>
    </NavigationWrapper>
  );
};

export default NavigationBar;
