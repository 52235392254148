import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  margin: 0 auto;
  margin-top: 74px;
  padding: 18px;
  width: 500px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  .information-card {
    display: flex;
    margin: 0 auto;
    margin-top: 74px;
    padding: 18px;
    width: 500px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .patient-image-container img {
    width: 250px;
    height: 250px;
  }

  .patient-information-container {
    text-align: initial;
  }

  .patient-information-container {
    margin-left: 15px;
  }

  .information {
    color: #045185;
    text-shadow: 0 0 0.25px #045185;
    text-transform: capitalize;
    font-size: 13px;
  }

  .texty {
    display: contents;
  }

  .title {
    font-weight: 500;
    font-size: 14px;
  }

  .communication-tab {
    margin-top: 31px;
    border-top: 1px solid;
  }
`;

export { CardContainer };
