import React, { useEffect, useState } from "react";

import { DatePicker, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

import {
  getAverageStay,
  getAdmissionData,
  admissionInformationSelector,
  getBedOccupancyData,
} from "../../Slices/AdmissionSlices";
import useWindowDimensions from "../../../../../components/common/useWindowDimensions ";

import FilterButton from "./Components/FilterButtons";
import DataPatientCard from "./Components/DataCards";
import DoctorAdmission from "./Components/DoctorAdmission";
import Scrollbars from "react-custom-scrollbars";
import PieChart from "./Components/PieChart";
import BarGraph from "./Components/BarGraph";
import moment from "moment";
import {
  getBedData,
  bedDataInformationSelector,
} from "../../Slices/BedDataSlice";

const Admission = () => {
  const { RangePicker } = DatePicker;
  const { width } = useWindowDimensions();

  const dateFormat = "DD/MM/YYYY";
  const [selectedDate, setSelectedDate] = useState([]);
  const [dateSelected, setDateSelected] = useState(7);
  const [activeButton, setActiveButton] = useState([]);

  const doctorProfile = JSON.parse(localStorage.getItem("doctorProfile"));

  const dispatch = useDispatch();

  const { averageStay, admissionData, currentBedOccupancy, isFetching } = useSelector(
    admissionInformationSelector
  );

  const getDate = (from_date, to_date, days) => {
    setDateSelected(days);
    dispatch(
      getAverageStay({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getAdmissionData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getBedData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getBedOccupancyData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location
      })
    );
  };

  const { bedData } = useSelector(bedDataInformationSelector);

  useEffect(() => {
    dispatch(
      getAverageStay({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getAdmissionData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getBedData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getBedOccupancyData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location
      })
    );
  }, []);

  const filterResult = () => {
    setActiveButton(null);
    dispatch(
      getAverageStay({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0])?.format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1])?.format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getAdmissionData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0])?.format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1])?.format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getBedData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getBedOccupancyData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location
      })
    );
  };

  const onChange = (dateString) => {
    setSelectedDate(dateString);
  };

  return (
    <>
      <Scrollbars style={{ height: width <= 414 ? "90vh" : "95vh" }}>
        <div className="range-selector">
          <RangePicker
            defaultValue={[
              moment(moment().subtract(7, "d")),
              moment(new Date()),
            ]}
            format={dateFormat}
            onChange={onChange}
            value={
              selectedDate?.length
                ? [selectedDate[0], selectedDate[1]]
                : [
                    moment(moment().subtract(dateSelected, "d")),
                    moment(new Date()),
                  ]
            }
            className="custom-range-picker"
          />
          <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={() => filterResult()}
            className="filter-date-button"
          />
          <span className="warning-txt">
            *Please Select a range of date's to show the data insights
          </span>
        </div>
        <FilterButton setFilterDate={getDate} setSelectedDate={setSelectedDate} activeButton={activeButton} setActiveButton={setActiveButton} />
        <DataPatientCard
          averageStay={averageStay}
          totalAdmission={admissionData?.total_numbers_of_admission}
          bedOccupancy={bedData?.avg_bed_occupancy}
          isFetching={isFetching}
          currentBedOccupancy = {currentBedOccupancy}
        />
        <div className="bottom-header-filter">
          <BarGraph bedData={bedData} isFetching={isFetching} />
        </div>
        <div className="top-header-filter">
          <DoctorAdmission
            admissions={admissionData?.number_of_admission_per_doctor}
            isFetching={isFetching}
          />
          <PieChart genderDetails={admissionData.age_gender_patient_data} />
        </div>
      </Scrollbars>
    </>
  );
};

export default Admission;
