import styled from "styled-components";

const LeftSideBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  section.ant-layout.ant-layout-has-sider {
    justify-content: flex-end;
  }

  button.ant-btn.ant-btn-primary.ant-input-search-button {
    background-color: #045185 !important;
    border-color: #045185 !important;
  }

  aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger,
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
    min-height: 100vh;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .search-container {
    margin-bottom: 12px;
  }

  .dark-mode {
    background-color: rgb(6, 12, 35);
    color: #fff;
  }

  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
    margin-top: 5px !important;
  }

  aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed.ant-layout-sider-zero-width.left-panel {
    width: 0 !important;
  }

  .pred-logo {
    display: flex;
    margin-top: 0.5em;
    margin-bottom: 45px;
    margin-left: 70px;

    font-size: 38px;
  }

  .pred-red {
    color: #045185;
  }

  .login-txt {
    color: #045185;
  }

  .pred-gray {
    color: #4d4d4d;
  }

  .pred-blue {
    color: #e21c21;
  }

  .profile-content {
    margin: 18px 26px;
    height: 95vh;
  }

  .profile-content h3 {
    color: #045185;
    margin-bottom: 20px;
  }

  span.ant-input-group-wrapper.ant-input-search.ant-input-search-with-button {
    margin-bottom: 2px;
  }

  button.ant-btn.ant-btn-primary {
    background: #045185;
    border: #045185;
  }

  .site-drawer-render-in-current-wrapper {
    position: relative;
    height: 150px;
    overflow: hidden;
    text-align: center;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
  }

  .search-cat {
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    color: #045185;
    cursor: pointer;
  }

  .patient-card {
    margin-top: 18px;
    height: 145px;
    background-color: grey;
    border-radius: 10px;
    background-color: rgb(245, 245, 245);
    border-radius: 10 px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  }

  .details-head {
    font-weight: 500;
  }

  .ant-drawer-body {
    text-align: start;
    padding: 0 20px;
  }

  .more-text {
    margin-bottom: 4px;
    font-weight: 500;
    display: block;
    cursor: pointer;
  }

  .flex-box {
    display: flex;
  }

  .details-parent {
    display: block;
    text-align: initial;
  }

  .patient-card img {
    width: 60px;
    height: 60px;
    border-radius: 40px;
  }

  .patient-information {
    margin-top: 10px;
    color: #045185;
    font-size: 13px;
  }

  .image-container {
    padding: 12px;
  }

  .button-container button {
    margin: 5px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
  }

  .prescription-btn {
    background-color: #f1f1f1 !important;
    border: 1 px solid #f1f1f1 !important;
    color: #777777;
  }

  .consultant-btn {
    background-color: #045185;
    color: white;
    border-color: #045185 !important;
  }

  .pred-logo {
    margin-bottom: 10px;
  }

  .toggle-icon {
    margin-top: 18px;
    font-size: 17px;
    cursor: pointer;
  }

  .ant-layout-sider-children {
    height: 100vh;
  }

  .logo .pred-logo {
    margin-bottom: 3px;
    margin-left: 28px;
    margin-top: 2px;
  }

  h1.short-logo {
    color: #045185;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 21px;
    margin-bottom: -5px;
  }

  .dot {
    color: #4d4d4d;
  }

  .button-container {
    position: absolute;
    right: 15px;
  }

  .h-text {
    text-transform: lowercase;
    color: #e21c21;
  }
`;

export { LeftSideBarWrapper };
