import { List, Avatar } from "antd";

const DoctorAdmission = ({ admissions }) => {
  let total = 0;

  admissions?.map((items) => (total = total + items.number_of_admission));

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={admissions}
        header={
          <div className="patient-container">
            <h1 className="graph-heading-container">
              Number of admissions per doctor
            </h1>

            <h1 className="graph-heading-container total-container">
              Total:- {total}
            </h1>
          </div>
        }
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar src="https://www.medstar911.org/wp-content/uploads/2019/11/Doctor-placeholder-image.jpg" />
              }
              title={item?.doctor_profile.full_name}
              description={item?.number_of_admission}
              className="list-data"
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default DoctorAdmission;
