import React, { useEffect } from "react";

//importing antd components
import { Form, Input } from "antd";

//importing common components
import Logo from "../../../../../components/common/logo";
import {
  otpErrorNotification,
  otpSuccessNotification,
} from "../../../../../components/common/Notification";
import SubmitButton from "../../../../../components/commonFormElements/SubmitButton";
import ContactInformation from "../../../../../components/common/ContactInformation";

const OtpLoginForm = (props) => {
  const {
    isFetching,
    slideDown,
    setSlideUP,
    setSlideDown,
    onFinish,
    onFinishFailed,
    isSuccess,
    isError,
  } = props;

  useEffect(() => {
    if (isSuccess) {
      otpSuccessNotification();
    }

    if (isError) {
      otpErrorNotification();
    }
  }, [isSuccess, isError]);

  var imgUrl = `${process.env.PUBLIC_URL}/pred_health_wo_bg.png`

  return (
    <div className={!slideDown ? "login slide-up" : "login bg-white-color "}>
      <div className="center">
        <h2
          className={!slideDown ? "form-title" : "form-title hide-title"}
          id="login"
          onClick={() => {
            setSlideDown(!slideDown);
            setSlideUP(true);
          }}
        >
          <span>or</span>Login with otp
        </h2>
        {/* <Logo /> */}
        <img
          src={imgUrl}
          alt="logo"
          width="100px"
          className="omichealth-logo"
        /> 
        <div className="form-holder">
          <Form
            name="otp-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="on"
          >
            {!isSuccess ? (
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input placeholder="Enter phone number" />
              </Form.Item>
            ) : (
              <Form.Item
                name="otpNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your OTP",
                  },
                ]}
              >
                <Input placeholder="Enter Your OTP" />
              </Form.Item>
            )}

            <SubmitButton buttonTxt="Verify" isFetching={isFetching} />
          </Form>
        </div>
        <ContactInformation />
      </div>
    </div>
  );
};

export default OtpLoginForm;
