import { Card, Spin } from "antd";
import { UsergroupAddOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { FaBed } from "react-icons/fa";

const DataPatientCard = ({
  averageStay,
  totalAdmission,
  bedOccupancy,
  currentBedOccupancy,
  isFetching,
}) => {
  return (
    <div className="col-container">
      <Card bordered={false}>
        <div className="content">
          <div className="icon">
            <UsergroupAddOutlined />
          </div>
          <div className="content-values">
            <h2 className="card-title">Total Admissions</h2>
            {isFetching ? (
              <>
                <Spin />
              </>
            ) : (
              <span className="card-value">
                {totalAdmission ? totalAdmission : 0}
              </span>
            )}
          </div>
        </div>
      </Card>

      <Card bordered={false} className="avg-len-container">
        <div className="content">
          <div className="icon">
            <FieldTimeOutlined />
          </div>
          <div className="content-values">
            <h2 className="card-title avg-len">Average length of stay</h2>
            <span className="card-value">
              {isFetching ? (
                <>
                  <Spin />
                </>
              ) : (
                <>
                  {averageStay ? (averageStay / 24).toFixed(2) : 0}
                  <span className="sub-details"> days</span>
                </>
              )}
            </span>
          </div>
        </div>
      </Card>

      <Card bordered={false}>
        <div className="content">
          <div className="icon">
            <FaBed />
          </div>
          <div className="content-values">
            <h2 className="card-title">Average Bed Occupancy</h2>
            <span className="card-value">
              {isFetching ? (
                <>
                  <Spin />
                </>
              ) : (
                <span className="card-value">
                  {bedOccupancy ? bedOccupancy?.toFixed(2) : 0}
                </span>
              )}
            </span>
          </div>
        </div>
      </Card>

      <Card bordered={false}>
        <div className="content">
          <div className="icon">
            <FaBed />
          </div>
          <div className="content-values">
            <h2 className="card-title">Current Bed Occupancy</h2>
            <span className="card-value">
              {isFetching ? (
                <>
                  <Spin />
                </>
              ) : (
                <span className="card-value">
                  {currentBedOccupancy ? currentBedOccupancy?.toFixed(0) : 0}
                </span>
              )}
            </span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DataPatientCard;
