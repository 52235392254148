import styled from "styled-components";

const ParentWrapper = styled.div`
  height: 100vh;
  display: flex;
  background-color: #eaeef3;
  overflow: hidden;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    .image-container {
      display: none !important;
    }

    .content-container {
      min-width: 0;
      margin: 0 auto;
    }

    .form-structure {
      margin: 0 !important;
    }
  }

  .ant-input,
  .ant-input-password {
    outline: none;
    line-height: 30px;
    padding: 8px 15px;
    font-size: 13px;
    border-radius: 12px;
    border-color: #045185;
    background-color: #e8f0fe;
    color: #045185;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #e21c21;
  }

  .ant-notification-notice-message {
    color: #fff;
  }

  .image-container {
    width: 1103px;
    background-color: #eaeef3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-notification-notice.error-text.ant-notification-notice-error.ant-notification-notice-closable {
    background-color: #fff2f0;
    border: 1px solid #ffacaa;
  }

  .ant-form label {
    font-size: 14px;
    color: #045185;
  }

  .login-btn button {
    height: 41px;
    width: 196px;
    border-radius: 12px;
    background-color: transparent;
    color: #045185;
    border-color: #045185;
  }

  .error-text {
    background-color: #fff2f0;
    border: 1px solid #ffbbb9;
    width: 100%;
  }

  .pred-logo {
    margin-top: 0.5em;
    margin-bottom: 45px;

    font-size: 38px;
  }

  .pred-red {
    color: #045185;
  }

  .login-txt {
    color: #045185;
  }

  .pred-gray {
    color: #4d4d4d;
  }

  .pred-blue {
    color: #e21c21;
  }

  .banner-image {
    max-width: 100%;
    width: 464px;
    min-width: 85%;
    display: block;
    margin: auto;
  }

  #login-form {
    margin: 0 10px;
  }

  .login-container {
    background-color: #fff;
    border-radius: 2rem;
    margin: 29px;
    width: 350px;
    height: 520px;
    position: relative;
    overflow: hidden;
  }

  .content-container {
    background-color: #eaeef3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-btn .ant-form-item-control-input-content {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .login-btn .ant-form-item-control-input-content a {
    position: relative;
    left: 0;
    transition: all 0.35s ease-Out;
  }

  .login-otp {
    position: absolute;
    width: 100%;
    bottom: 20px;
    background-color: #b8ccdb;
    z-index: 2;
    font-size: 10px;

    h2 {
      color: #045185;
    }

    span {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .login-otp::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -20px;
    -webkit-transform: translate(-50%, 0);
    width: 200%;
    height: 250px;
    z-index: -1;
    background-color: #b8ccdb;
    -webkit-transition: all 0.3s ease;
  }

  .login-otp h2 {
    cursor: pointer;
  }

  #dub-arrow {
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 25px;
    background: #045185;
    border-color: #045185;
    left: -300px;
    position: absolute;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.35s ease-Out;
    transition: all 0.35s ease-Out;
    bottom: 0;
  }

  .login-btn .ant-form-item-control-input-content img {
    width: 20px;
    height: auto;
  }

  .login-btn .ant-form-item-control-input-content:hover #dub-arrow {
    left: 0;
  }

  .login-btn .ant-form-item-control-input-content:hover a {
    left: 150px;
  }

  .form-structure {
    background-color: #fff;
    border-radius: 18px;
    width: 350px;
    height: 520px;
    position: relative;
    overflow: hidden;
    margin: 0 80px;

    &::after {
      content: "";
      opacity: 0.8;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 500px;
    }

    .signup {
      position: absolute;
      top: 42%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      width: 65%;
      z-index: 5;
      -webkit-transition: all 0.3s ease;

      &.slide-up {
        top: 5%;
        -webkit-transform: translate(-50%, 0%);
        -webkit-transition: all 0.3s ease;
      }

      &.slide-up .form-holder,
      &.slide-up .submit-btn {
        opacity: 0;
        visibility: hidden;
      }

      &.slide-up .form-title {
        font-size: 1em;
        cursor: pointer;
        color: #045185;
      }

      &.slide-up .form-title span {
        margin-right: 5px;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.3s ease;
      }

      .form-title {
        color: #fff;
        font-size: 1.7em;
        text-align: center;

        span {
          color: rgba(0, 0, 0, 0.4);
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all 0.3s ease;
        }
      }

      .form-holder {
        border-radius: 15px;
        background-color: #fff;
        overflow: hidden;
        margin-top: -10px;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.3s ease;

        .input {
          border: 0;
          outline: none;
          box-shadow: none;
          display: block;
          height: 30px;
          line-height: 30px;
          padding: 8px 15px;
          border-bottom: 1px solid #eee;
          width: 100%;
          font-size: 12px;

          &:last-child {
            border-bottom: 0;
          }
          &::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.4);
          }
        }
      }

      .submit-btn {
        background-color: rgba(0, 0, 0, 0.4);
        color: rgba(256, 256, 256, 0.7);
        border: 0;
        border-radius: 15px;
        display: block;
        margin: 15px auto;
        padding: 15px 45px;
        width: 100%;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.3s ease;

        &:hover {
          transition: all 0.3s ease;
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }

    .hide-title {
      display: none;
    }

    .form-holder {
      margin: 0px 76px;
    }

    .bg-white-color {
      background-color: #cfdce6 !important;
      top: 13% !important;
    }

    .login {
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      z-index: 5;
      -webkit-transition: all 0.3s ease;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: -10px;
        -webkit-transform: translate(-50%, 0);
        background-color: #cfdce6;
        width: 200%;
        height: 250px;
        z-index: 4;
        -webkit-transition: all 0.3s ease;
      }

      .center {
        position: absolute;
        top: calc(53% - 10%);
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        width: 100%;
        z-index: 5;
        -webkit-transition: all 0.3s ease;

        .form-title {
          color: #000;
          font-size: 1.7em;
          text-align: center;

          span {
            color: rgba(0, 0, 0, 0.4);
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all 0.3s ease;
          }
        }

        .form-holder {
          border-radius: 15px;
          background-color: transparent;
          border: 1px solid transparent;
          overflow: hidden;
          margin-top: -25px;
          opacity: 1;
          visibility: visible;
          -webkit-transition: all 0.3s ease;

          .input {
            border: 0;
            outline: none;
            box-shadow: none;
            display: block;
            height: 30px;
            line-height: 30px;
            padding: 8px 15px;
            border-bottom: 1px solid #eee;
            width: 100%;
            font-size: 12px;

            &:last-child {
              border-bottom: 0;
            }
            &::-webkit-input-placeholder {
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }

        .form-title {
          color: #045185;
        }

        .submit-btn {
          background-color: #6b92a4;
          color: rgba(256, 256, 256, 0.7);
          border: 0;
          border-radius: 15px;
          display: block;
          margin: 15px auto;
          padding: 15px 45px;
          width: 100%;
          font-size: 13px;
          font-weight: bold;
          cursor: pointer;
          opacity: 1;
          visibility: visible;
          -webkit-transition: all 0.3s ease;

          &:hover {
            transition: all 0.3s ease;
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }

      &.slide-up {
        top: 90%;
        -webkit-transition: all 0.3s ease;
      }

      &.slide-up .center {
        top: 10%;
        -webkit-transform: translate(-50%, 0%);
        -webkit-transition: all 0.3s ease;
      }

      &.slide-up .form-holder,
      &.slide-up .submit-btn {
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.3s ease;
      }

      &.slide-up .form-title {
        font-size: 1em;
        margin: 0;
        padding: 0;
        cursor: pointer;
        -webkit-transition: all 0.3s ease;
      }

      &.slide-up .form-title span {
        margin-right: 5px;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.3s ease;
      }
    }

    .connection-information span {
      display: block;
      color: #045185;
      margin-bottom: 1px;
    }

    .contact-info {
      margin-top: 25px;
      color: #045185;
      font-weight: 480;
    }

    .contact-info a {
      margin: 0 7px;
      color: #045185;
    }

    span.anticon.anticon-phone {
      margin-left: 5px;
    }

    .omichealth-logo {
      margin-top: 3em;
      margin-bottom: 43px;
      width: 190px;
    }

    button.ant-btn.ant-btn-primary.logout-btn {
      margin-left: 13px;
    }
  }
`;

export { ParentWrapper };
