import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/Login";

import { PrivateRoute } from "./routes/PrivateRoute";
import DataInsightsDashboard from "./pages/DataInsights";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact component={Login} path="/login" />

          <Route
            exact
            component={DataInsightsDashboard}
            path="/data-insights"
          />
          <PrivateRoute
            exact
            component={DataInsightsDashboard}
            path="/authenticate/:token/:key"
          />

          <PrivateRoute exact component={DataInsightsDashboard} path="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
