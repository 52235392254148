import styled from "styled-components";

const ParentWrapper = styled.div`
  .pred-logo {
  }

  .pred-red {
    color: #045185;
  }

  .login-txt {
    color: #045185;
  }

  .pred-gray {
    color: #4d4d4d;
  }

  .pred-blue {
    color: #e21c21;
  }
`;

export { ParentWrapper };
