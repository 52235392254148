import styled from "styled-components";

const TabWrapper = styled.div`
  padding: 5px .card-container p {
    margin: 0;
  }
  .card-container > .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }
  .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    height: 93vh;
    padding: 16px;
    background: #fff;
    display: flex;
  }
  .card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
  }
  [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
  [data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  [data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
  }
  [data-theme="dark"]
    .card-container
    > .ant-tabs-card
    .ant-tabs-content
    > .ant-tabs-tabpane {
    background: #141414;
  }
  [data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
  }

  .ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #045185;
  }

  .ant-result-title {
    font-weight: 500;
    color: #045185;
  }

  .ant-result-subtitle {
    color: #045185;
  }

  .App-navigation {
    position: sticky;
    top: 0;
    background: #282c34;
    padding: 1rem 0;
  }

  .App-navigation-item {
    padding: 0 1rem;
    color: #fff;
    font-size: 1.2rem;
  }
  .App-navigation-item--active {
    font-weight: bold;
  }

  .container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  //   .container::-webkit-scrollbar {
  //     width: 10px;
  //   }

  /* width */
  .container::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  /* Handle */
  .container::-webkit-scrollbar-thumb {
    background: #045185;
    border-radius: 10px;
  }

  /* Handle on hover */
  .container::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  .ant-tabs-nav {
    height: 30px;
    font-weight: 500;
    font-size: 21px;
  }

  .mobile-tabs {
    padding: 0 !important;
  }

  section.ant-layout.ant-layout-has-sider {
    justify-content: flex-end;
  }

  button.ant-btn.ant-btn-primary.ant-input-search-button {
    background-color: #045185 !important;
    border-color: #045185 !important;
  }

  aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger,
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
    min-height: 100vh;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .search-container {
    margin-bottom: 12px;
  }

  .dark-mode {
    background-color: rgb(6, 12, 35);
    color: #fff;
  }

  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
    margin-top: 5px !important;
  }

  aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed.ant-layout-sider-zero-width.left-panel {
    width: 0 !important;
  }

  .pred-logo {
    display: flex;
    margin-top: 0.5em;
    margin-bottom: 45px;
    margin-left: 70px;

    font-size: 38px;
  }

  .pred-red {
    color: #045185;
  }

  .login-txt {
    color: #045185;
  }

  .pred-gray {
    color: #4d4d4d;
  }

  .pred-blue {
    color: #e21c21;
  }

  .profile-content {
    margin: 18px 26px;
    height: 95vh;
  }

  .profile-content h3 {
    color: #045185;
    margin-bottom: 20px;
  }

  span.ant-input-group-wrapper.ant-input-search.ant-input-search-with-button {
    margin-bottom: 2px;
  }

  .site-drawer-render-in-current-wrapper {
    position: relative;
    height: 150px;
    overflow: hidden;
    text-align: center;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
  }

  .search-cat {
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    color: #045185;
    cursor: pointer;
  }

  .patient-card {
    margin-top: 18px;
    height: 145px;
    background-color: grey;
    border-radius: 10px;
    background-color: rgb(245, 245, 245);
    border-radius: 10 px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  }

  .details-head {
    font-weight: 500;
  }

  .ant-drawer-body {
    text-align: start;
    padding: 0 20px;
  }

  .more-text {
    margin-bottom: 4px;
    font-weight: 500;
    display: block;
    cursor: pointer;
  }

  .flex-box {
    display: flex;
  }

  .details-parent {
    display: block;
    text-align: initial;
  }

  .patient-card img {
    width: 60px;
    height: 60px;
    border-radius: 40px;
  }

  .patient-information {
    margin-top: 10px;
    color: #045185;
    font-size: 13px;
  }

  .image-container {
    padding: 12px;
  }

  .button-container button {
    font-weight: 500;
    font-size: 8px;
    box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
  }

  .prescription-btn {
    background-color: #f1f1f1 !important;
    border: 1 px solid #f1f1f1 !important;
    color: #777777;
  }

  .consultant-btn {
    margin-left: 15px;
    background-color: #045185;
    color: white;
    border-color: #045185 !important;
  }

  .pred-logo {
    margin-bottom: 10px;
  }

  .view-more-patient-list {
    padding: 20px !important;
  }

  .ant-radio-group.ant-radio-group-solid.gender-selector {
    box-shadow: rgb(0 0 0 / 9%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px,
      rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px,
      rgb(0 0 0 / 9%) 0px 32px 16px;
  }

  @media screen and (max-width: 600px) {
    .warning-txt {
      font-size: 10px !important;
    }

    .ant-list-item-meta-description {
      font-size: 10px !important;
    }

    h4.ant-list-item-meta-title {
      font-size: 9px !important;
    }

    .range-selector-surgery {
      margin-bottom: 8px;
    }

    .button-container {
      margin: 12px 0;
    }

    label.ant-radio-button-wrapper {
      font-size: 10px !important;
      height: 29px;
      width: 65px;
    }

    .ant-list-item-meta-description {
      font-size: 17px;
    }

    .ant-list-item-meta-description {
      font-size: 12px;
      font-weight: 600;
      color: #045185;
    }

    .ant-list.ant-list-split {
      margin-bottom: 10px;
    }

    .filter-button {
      font-size: 6px !important;
      height: 25px !important;
      width: 73px !important;
      margin: 2px 8px !important;
    }
  }
`;

export { TabWrapper };
