import styled from "styled-components";

const PanelContainer = styled.div`
  [data-theme="compact"]
    .site-collapse-custom-collapse
    .site-collapse-custom-panel,
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 24px;
    overflow: hidden;
    background: #f7f7f7;
    border: 0px;
    border-radius: 2px;
  }

  .medical-panel {
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .medical-panel .ant-collapse-header {
    text-align: left;
    font-weight: 600;
    font-size: 15px;
    color: #045185;
  }
`;

export { PanelContainer };
