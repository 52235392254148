import { Card } from "antd";

const { Meta } = Card;

const InformationCard = ({ userDetails }) => {
  return (
    <Card
      hoverable
      style={{ width: 200 }}
      cover={
        <img
          alt="example"
          src="https://www.w3schools.com/howto/img_avatar.png"
        />
      }
    >
      <Meta title={userDetails?.full_name} description={userDetails.email} />
    </Card>
  );
};

export default InformationCard;
