import { List, Avatar, Spin } from "antd";

const DoctorAdmission = ({ revenuePerSurgeryList, isFetching }) => {
  let total = 0;

  revenuePerSurgeryList?.map((items) => {
    total = total + items.revenue;
  });

  return (
    <>
      {isFetching ? (
        <>
          <div className="pie-graph-container" style={{ display: 'flex', width: window.innerWidth > 600 ? '49%' : '100%', justifyContent: 'center', padding: 30, margin: 10 }}>
              <Spin size="large" />
          </div>
        </>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={revenuePerSurgeryList}
          header={
            <div className="patient-container">
              <h1 className="graph-heading-container">Revenue Per Surgery</h1>

              <h1 className="graph-heading-container total-container">
                Total:-{" "}
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(parseInt(total).toFixed(2))}
              </h1>
            </div>
          }
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar src="https://www.medstar911.org/wp-content/uploads/2019/11/Doctor-placeholder-image.jpg" />
                }
                title={item?.surgery?.name?.trim()}
                description={new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(parseInt(item?.revenue).toFixed(2))}
                className="list-data"
              />
            </List.Item>
          )}
        />
      )}
    </>
  );
};

export default DoctorAdmission;
