/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//Importing external components
import { Helmet, HelmetProvider } from "react-helmet-async";

//Importing internals component5s
import ImageContainer from "./Components/ImageContainer";
import FromContainer from "./Components/FormContainer";

import { ParentWrapper } from "./style";
import queryString from "query-string";

const Login = (props) => {
  const [claimId, setClaimId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    localStorage.clear();

    const value = queryString.parse(props.location.search);
    setClaimId(value.claim);
    setPatientId(value.patient);
    setToken(value.token);
  }, []);
  return (
    <HelmetProvider>
      <Helmet bodyAttributes={{ style: "background-color : #eaeef3" }} />
      <ParentWrapper>
        <ImageContainer />
        <FromContainer claimId={claimId} patientId={patientId} token={token} />
      </ParentWrapper>
    </HelmetProvider>
  );
};

export default Login;
