import { Form, Button } from "antd";
import { LoginOutlined } from "@ant-design/icons";

const SubmitButton = ({ buttonTxt, isFetching }) => {
  return (
    <Form.Item className="login-btn">
      <Button
        type="primary"
        htmlType="submit"
        icon={<LoginOutlined />}
        loading={isFetching}
      >
        {buttonTxt}
      </Button>
    </Form.Item>
  );
};

export default SubmitButton;
