import React, { useEffect, useState } from "react";

import { DatePicker, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

import {
  admissionInformationSelector,
  getReferralsData,
} from "../../Slices/AdmissionSlices";

import FilterButton from "./Components/FilterButtons";
import DoctorAdmission from "./Components/DoctorAdmission";
import Scrollbars from "react-custom-scrollbars";
import PieChart from "./Components/PieChart";
import moment from "moment";

const Referals = () => {
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";
  const [selectedDate, setSelectedDate] = useState([]);
  const [dateSelected, setDateSelected] = useState(7);
  const [activeButton, setActiveButton] = useState([]);

  const doctorProfile = JSON.parse(localStorage.getItem("doctorProfile"));

  const dispatch = useDispatch();

  const { referralData, isFetching } = useSelector(
    admissionInformationSelector
  );

  const getDate = (from_date, to_date, days) => {
    setDateSelected(days);

    dispatch(
      getReferralsData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
      })
    );
  };

  useEffect(() => {
    dispatch(
      getReferralsData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      })
    );
  }, []);

  const filterResult = () => {
    setActiveButton(null);
    dispatch(
      getReferralsData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0])?.format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1])?.format("YYYY-MM-DD"),
      })
    );
  };

  const onChange = (dateString) => {
    setSelectedDate(dateString);
  };

  return (
    <>
      <Scrollbars style={{ height: "89vh" }}>
        <div className="range-selector">
          <RangePicker
            defaultValue={[
              moment(moment().subtract(7, "d")),
              moment(new Date()),
            ]}
            value={
              selectedDate?.length
                ? [selectedDate[0], selectedDate[1]]
                : [
                    moment(moment().subtract(dateSelected, "d")),
                    moment(new Date()),
                  ]
            }
            format={dateFormat}
            onChange={onChange}
            className="custom-range-picker"
          />
          <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={() => filterResult()}
            className="filter-date-button"
          />
          <span className="warning-txt">
            *Please Select a range of date's to show the data insights
          </span>
        </div>
        <FilterButton setFilterDate={getDate} setSelectedDate={setSelectedDate} activeButton={activeButton} setActiveButton={setActiveButton} />

        <div className="top-header-filter">
          <DoctorAdmission
            admissions={referralData?.admission_individual_referral_data}
            isFetching={isFetching}
          />
          <PieChart genderDetails={referralData?.admission_referral_data} isFetching={isFetching}/>
        </div>
      </Scrollbars>
    </>
  );
};

export default Referals;
