import React, { useState, useEffect } from "react";
import { Empty, Spin } from "antd";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarGraph = ({ bedData, isFetching }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (bedData?.bed_occupancy_data && bedData?.bed_occupancy_data) {
      let modifiedData = bedData?.bed_occupancy_data.map((items) => {
        return {
          date: moment(items?.date).format("DD/MM/YYYY"),
          number_of_bed_occupied: items?.number_of_bed_occupied,
        };
      });
      setData(modifiedData);
    }
  }, [bedData]);
  return (
    <div className="bar-graph-container no-padding">
      {data && data?.length ? (
        <>
          <h1 className="graph-heading-container left">
            bed occupancy (number of beds per day)
          </h1>
          {isFetching ? (
            <div className="middle">
              <Spin size="large" />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={500}
                data={data}
                margin={{
                  top: 5,
                  right: 0,
                  left: 1,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="number_of_bed_occupied"
                  stroke="#045185"
                  activeDot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </>
      ) : (
        <>
          <h1 className="graph-heading-container">
            bed occupancy (number of beds per day)
          </h1>
          <Empty />
        </>
      )}
    </div>
  );
};

export default BarGraph;
