import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

import contactInformation from "../../const/ContactInformation";
import { ParentWrapper } from "./logoStyle";

const ContactInformation = () => {
  const { phoneNo, emailId } = contactInformation;
  return (
    <ParentWrapper>
      <div className="connection-information">
        <span>If your phone number doesn't work,</span>
        <span>please contact us at:</span>
      </div>
      <div className="contact-info">
        <MailOutlined />
        <a href={`mailto:${emailId}`}>{emailId}</a>|
        <PhoneOutlined />
        <a href={`tel:${phoneNo}`}>{phoneNo}</a>
      </div>
    </ParentWrapper>
  );
};

export default ContactInformation;
