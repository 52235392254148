import React, { useState } from "react";
import { Modal } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { ModalWrapper } from "./styles";

const SupportModal = ({ supportModal, openSupportModal }) => {
  return (
    <ModalWrapper className="modal-wrapper">
      <Modal
        title="Basic Modal"
        visible={supportModal}
        onOk={() => openSupportModal(!supportModal)}
        onCancel={() => openSupportModal(!supportModal)}
      >
        <div className="support-container">
          <div className="contact-container">
            <div className="content-container">
              <div className="contact-icon">
                <PhoneOutlined />
              </div>
              <ul className="tel-contact">
                <li className="heading">Contact Us</li>
                <li className="number">
                  <a href="tel:+919920596642">+91 992 0596642</a>
                </li>
                <li className="number">
                  <a href="tel:+919920053464">+91 992 0053464</a>
                </li>
              </ul>

              <ul className="whats-contact">
                <li className="heading">WhatsApp Us</li>
                <li className="number">
                  <a href="https://wa.me/919920596642" target="_blank">
                    +91 992 0596642
                  </a>
                </li>
              </ul>

              <ul className="email-contact">
                <li className="heading">Send us an email</li>
                <li className="number">
                  <a href="mailto:support@pred.health">support@pred.health</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="form-container"></div>
        </div>
      </Modal>
    </ModalWrapper>
  );
};

export default SupportModal;
