import styled from "styled-components";

const PanelContainer = styled.div`
  .medical-panel {
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .medical-panel .ant-collapse-header {
    text-align: left;
    font-weight: 600;
    font-size: 15px;
    color: #045185;
  }

  .image-btn, .report-btn {
    font-size: 9.5px !important;
    width: 95px;
    padding: 7px 0;
    background-color: #045185;
    text-transform: capitalize;
  }

  span.anticon.anticon-eye {
    font-size: 15px;
  }

  .report-text {
    display: block;
  }

  span.anticon.anticon-file {
    font-size: 12px;
  }

  span.ant-avatar.ant-avatar-circle.ant-avatar-image {
    border-radius: 0px;
    width: 60px;
    height: 60px;
  }

  .ant-list-item-meta-content {
    text-align: initial;
    margin-left: 10px;
  }

  li.ant-list-item {
    padding: 17px 0;
  }

  h4.ant-list-item-meta-title a {
    color: #045185;
  }

  button.ant-btn.ant-btn-primary.ant-btn-round {
    background-color: #045185;
    border: none;
    font-size: 10px;
  }

  .report-content {
    font-size: 12px;
    text-align: end;
  }

  span.uploaded-date {
    display: block;
  }

  .uploaded-doc span {
    font-weight: 600;
  }

  .ant-collapse-header {
    border-bottom: 1px solid #045185;
  }

  .ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-left.site-collapse-custom-collapse {
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }

  .report-btn {
    margin-left: 5px;
    font-size: 9.5px;
    width: 95px;
    padding: 7px 0;
    background-color: #045185;
    text-transform: capitalize;
  }

  .image-btn {
    font-size: 9.5px;
    width: 95px;
    padding: 7px 0;
    background-color: #045185;
    text-transform: capitalize;
  }

  .mobile-view {
    width: 35px !important
  }

  .mobile-view-list {
    display : block !important
  }

  @media screen and (max-width: 576px) {
    .ant-list-item {
      flex-wrap: nowrap !important;
    }
`;

export { PanelContainer };
