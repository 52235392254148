/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { DatePicker, Button, Select, Modal, Form } from "antd";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

import {
  getInvoiceDetails,
  getInvoiceCategory,
  getCateegoryData,
  getCateegoryDataUpdated,
  invoiceInformationSelector,
  getUserDashboardData,
  saveUserDashboardData,
  setCatDeletedData,
} from "../../Slices/InvoiceSlice";

import FilterButton from "./Components/FilterButtons";
import DataPatientCard from "./Components/DataCards";
import DoctorAdmission from "./Components/DoctorAdmission";
import Scrollbars from "react-custom-scrollbars";
import BarGraph from "./Components/BarGraph";
import moment from "moment";

const Revenue = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const doctorProfile = JSON.parse(localStorage.getItem("doctorProfile"));

  const dispatch = useDispatch();
  const [addedDashbords, setAddedDahboard] = useState([]);
  const [addedCards, setAddedCards] = useState([]);

  const [selectedDate, setSelectedDate] = useState([]);
  const [openAddDashboardModal, setAddDashboard] = useState(false);
  const [allowCard, setAllowCard] = useState(false);
  const [dateSelected, setDateSelected] = useState(7);
  const [activeButton, setActiveButton] = useState([]);

  const [selectedCategory, setSelectedCat] = useState("");
  const [selectedGraphType, setSelectedGraphTye] = useState("");

  const {
    invoiceData,
    categories,
    catData,
    UserDashboardData,
    catDataUpdated,
    isFetching,
  } = useSelector(invoiceInformationSelector);

  let graphType = ["Bar-Graph", "Line-Graph", "Show-Total-Value"];

  const dateFormat = "DD/MM/YYYY";

  const getDate = async (from_date, to_date, days) => {
    setDateSelected(days);

    dispatch(
      getInvoiceDetails({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getInvoiceCategory({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
      })
    );

    if (addedDashbords && addedDashbords.length > 0) {
      addedDashbords.map((items) =>
        dispatch(
          getCateegoryDataUpdated({
            token: localStorage.getItem("token"),
            locationId: doctorProfile?.selected_location,
            from_date: moment(from_date).format("YYYY-MM-DD"),
            to_date: moment(to_date).format("YYYY-MM-DD"),
            category: items.value,
            uid: items.uid,
            type: items.type,
            cardAdded: items.cardAdded,
          })
        )
      );
    }
  };

  useEffect(() => {
    dispatch(
      getInvoiceDetails({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getInvoiceCategory({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getUserDashboardData({
        token: localStorage.getItem("token"),
      })
    );
  }, []);

  useEffect(() => {
    const uniqueObjects = [
      ...new Map(catDataUpdated?.map((item) => [item.uid, item])).values(),
    ];

    let total = 0;
    let data = uniqueObjects.map((item) => {
      return {
        title: item.value,
        cardAdded: item.cardAdded,
        uid: item.uid,
        key: item.uid,
        value: item?.data
          ?.map((item) => {
            return total + item.amount;
          })
          .reduce((a, b) => a + b, 0),
      };
    });

    setAddedDahboard(uniqueObjects);
    setAddedCards(data);
  }, [catDataUpdated]);

  useEffect(() => {
    if (catData && catData?.length) {
      let uid = Date.now().toString(36) + Math.random().toString(36).substr(2);

      setAddedDahboard([
        ...addedDashbords,
        {
          value: selectedCategory,
          type: selectedGraphType,
          data: catData,
          uid: uid,

          cardAdded: allowCard,
        },
      ]);

      if (catData && catData.length > 0 && allowCard) {
        let total = 0;

        catData.map((item) => (total = total + item.amount));

        setAddedCards([
          ...addedCards,
          {
            title: selectedCategory,
            value: total,
            uid: uid,
            cardAdded: allowCard,
          },
        ]);
      }
    }
  }, [catData]);

  useEffect(() => {
    dispatch(
      saveUserDashboardData({
        token: localStorage.getItem("token"),
        data_insights_json: addedDashbords,
      })
    );
  }, [addedDashbords]);

  useEffect(() => {
    setAddedDahboard([...UserDashboardData]);
    if (UserDashboardData) {
      let total = 0;
      let data = UserDashboardData.map((item) => {
        return {
          title: item.value,
          cardAdded: item?.cardAdded,
          uid: item?.uid,
          key: item?.uid,
          value: item?.data
            ?.map((item) => {
              return total + item.amount;
            })
            .reduce((a, b) => a + b, 0),
        };
      });
      setAddedCards(data);
    }
  }, [UserDashboardData]);

  const filterResult = () => {
    setActiveButton(null);
    dispatch(
      getInvoiceDetails({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getInvoiceCategory({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        to_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      })
    );
  };

  const addDashboard = (value, type) => {
    dispatch(
      getCateegoryData({
        token: localStorage.getItem("token"),
        locationId: doctorProfile?.selected_location,
        from_date: moment().subtract(360, "d").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        category: value,
      })
    );
    setSelectedCat(value);
    setSelectedGraphTye(type);
  };

  const removeDashboard = (index) => {
    const filteredDashboard = addedDashbords.filter(
      (item) => item.uid !== index
    );

    dispatch(
      setCatDeletedData({
        data: filteredDashboard,
      })
    );

    setAddedDahboard(filteredDashboard);
  };

  const removeCards = (index) => {
    const filteredDashboard = addedDashbords.filter(
      (item) => item.uid !== index
    );
    const filtereAddedCards = addedCards.filter((item) => item.uid !== index);

    dispatch(
      setCatDeletedData({
        data: filteredDashboard,
      })
    );

    setAddedCards(filtereAddedCards);
  };

  const onFinish = (values) => {
    if (values.type === "Show-Total-Value") {
      setAllowCard(true);
      addDashboard(values.category, values.type);
      setAddDashboard(false);
    } else {
      addDashboard(values.category, values.type);
      setAddDashboard(false);
      setAllowCard(false);
    }
  };

  function onChange(dateString) {
    setSelectedDate(dateString);
  }

  return (
    <>
      <Scrollbars style={{ height: "89vh" }}>
        <div className="header-container">
          <div className="range-selector">
            <RangePicker
              defaultValue={[
                moment(moment().subtract(7, "d")),
                moment(new Date()),
              ]}
              value={
                selectedDate?.length
                  ? [selectedDate[0], selectedDate[1]]
                  : [
                      moment(moment().subtract(dateSelected, "d")),
                      moment(new Date()),
                    ]
              }
              format={dateFormat}
              onChange={onChange}
              className="custom-range-picker"
            />
            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => filterResult()}
              className="filter-date-button"
            />
            <span className="warning-txt">
              *Please Select a range of date's to show the data insights
            </span>
          </div>
          <div className="add-button-container">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="add-btn"
              onClick={() => {
                setAddDashboard(true);
              }}
            >
              Add New Metric
            </Button>
          </div>
        </div>
        <FilterButton setFilterDate={getDate} setSelectedDate={setSelectedDate} activeButton={activeButton} setActiveButton={setActiveButton} />
        <Modal
          title="Add Dashboard"
          visible={openAddDashboardModal}
          footer={null}
          onCancel={() => {
            setAddDashboard(false);
          }}
        >
          <div className="form-container">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: false }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Select a category "
                name="category"
                rules={[
                  { required: true, message: "Please select the category!" },
                ]}
              >
                <Select style={{ width: 200 }} placeholder="Select a category">
                  {categories?.map((item) => {
                    return (
                      <Option value={item.name} key={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Select a graph type "
                name="type"
                rules={[
                  { required: true, message: "Please select the graph type!" },
                ]}
              >
                <Select style={{ width: 200 }} placeholder="Select a type">
                  {graphType.map((item) => {
                    return (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <div className="top-header-filter-revenue">
          <DataPatientCard
            totalRevenue={invoiceData.total_revenue}
            totalCollected={invoiceData.total_collected}
            totalOutstanding={invoiceData.total_outstanding}
            totalDiscount={invoiceData.total_discount}
            addedCards={addedCards}
            removeCards={removeCards}
            isFetching={isFetching}
          />
        </div>
        <DoctorAdmission
          admissions={invoiceData.revenue_from_primary_doctors}
          isFetching={isFetching}
        />

        {addedDashbords.map((items) => {
          return (
            <>
              <BarGraph
                key={items.uid}
                index={items.uid}
                removeDashboard={removeDashboard}
                title={items.value}
                data={items.data}
                categories={categories}
                type={items.type}
                allowCard={items.cardAdded}
                staticCat={categories}
              />
            </>
          );
        })}
      </Scrollbars>
    </>
  );
};

export default Revenue;
